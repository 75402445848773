import menu, { closeMenu, openMenu, openSubMenu } from "./components/menu";

menu.button.open.forEach((open)=>{
    open.addEventListener("click", openMenu);
});

menu.button.close.forEach((close)=>{
    close.addEventListener("click", closeMenu);
});
menu.overlay.addEventListener("click", closeMenu);
menu.dropDowns.forEach((dropDown) => {
    dropDown.addEventListener("click", openSubMenu);
});

//
const header= document.querySelector('.header-info');
const nav= document.querySelector('.header__nav');
const floatingNav=document.querySelector(".header-floating");

var menuChange= function (){

    let headerHeight = header.offsetHeight;
    let navHeight= nav.offsetHeight;

    let totalHeight= navHeight+headerHeight;

    if (window.scrollY>totalHeight && floatingNav.classList.contains('header-floating__hidden')){

        floatingNav.classList.toggle("header-floating__hidden");
    }else if(window.scrollY<=totalHeight &&!floatingNav.classList.contains('header-floating__hidden')){

        floatingNav.classList.toggle("header-floating__hidden");
    }
}

window.addEventListener('scroll', menuChange);
